var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('module-navigation-bar',{attrs:{"title":"Projects Overview"}},[_c('template',{slot:"module-nav-logo"},[_c('img',{attrs:{"src":"https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg","height":"40px","alt":""}})])],2),_c('div',{staticClass:"d-flex flex-1"},[_c('div',{staticClass:"d-flex flex-column flex-1 pa-2",staticStyle:{"width":"0"}},[_c('div',{staticClass:"px-5 py-2 d-flex align-center",staticStyle:{"max-width":"100vw"}},[_c('v-card',{attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex px-2 py-1 align-center"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-filter-variant")]),_c('v-select',{staticClass:"mr-2",attrs:{"items":['Corridor', 'Gallerij'],"dense":"","outlined":"","hide-details":"","placeholder":"Type","clearable":""},model:{value:(_vm.projectFilters.type),callback:function ($$v) {_vm.$set(_vm.projectFilters, "type", $$v)},expression:"projectFilters.type"}}),_c('v-select',{staticClass:"mr-2",attrs:{"items":_vm.distinctTasks,"dense":"","outlined":"","hide-details":"","placeholder":"Phase","clearable":""},model:{value:(_vm.projectFilters.phase),callback:function ($$v) {_vm.$set(_vm.projectFilters, "phase", $$v)},expression:"projectFilters.phase"}}),_c('v-chip',{staticClass:"mr-2",attrs:{"color":_vm.projectFilters.overdue
                  ? 'var(--v-primary-lighten2)'
                  : 'var(--v-secondary-lighten5)'},on:{"click":function($event){_vm.projectFilters.overdue = !_vm.projectFilters.overdue}}},[_vm._v(" Overdue ")]),_c('v-chip',{staticClass:"mr-2",attrs:{"color":_vm.projectFilters.wkb
                  ? 'var(--v-primary-lighten2)'
                  : 'var(--v-secondary-lighten5)'},on:{"click":function($event){_vm.projectFilters.wkb = !_vm.projectFilters.wkb}}},[_vm._v(" WKB ")]),_c('v-chip',{staticClass:"mr-2",attrs:{"color":_vm.projectFilters.redFlags
                  ? 'var(--v-primary-lighten2)'
                  : 'var(--v-secondary-lighten5)'},on:{"click":function($event){_vm.projectFilters.redFlags = !_vm.projectFilters.redFlags}}},[_vm._v(" Redflags ")]),_c('v-chip',{attrs:{"color":_vm.projectFilters.current
                  ? 'var(--v-primary-lighten2)'
                  : 'var(--v-secondary-lighten5)'},on:{"click":function($event){_vm.projectFilters.current = !_vm.projectFilters.current}}},[_vm._v(" Current ")])],1)]),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"mandatory":"","color":"primary","rounded":""},model:{value:(_vm.ganttScale),callback:function ($$v) {_vm.ganttScale=$$v},expression:"ganttScale"}},_vm._l((_vm.ganttZoomLevels),function(option){return _c('v-btn',{key:option.key},[_vm._v(" "+_vm._s(option.value)+" ")])}),1)],1),_c('v-progress-linear',{style:({
          opacity: _vm.$wait.is('module.project-planning.tasks.get') ? 1 : 0,
        }),attrs:{"color":"primary","indeterminate":"","height":"4px"}}),(!_vm.$wait.is('module.project-planning.tasks.get'))?_c('dhme-projects-dashboard-gantt-chart',{ref:"project-planning-gantt",staticClass:"flex-1",attrs:{"projects":_vm.filteredProjects}}):_vm._e()],1),_c('dhme-projects-dashboard-sidebar',{staticClass:"ma-2",style:(_vm.viewportStyle),on:{"update-data":_vm.fetchModuleData}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }