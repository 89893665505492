<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Projects Overview">
      <template slot="module-nav-logo">
        <img
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <div class="d-flex flex-1">
      <div class="d-flex flex-column flex-1 pa-2" style="width: 0">
        <div class="px-5 py-2 d-flex align-center" style="max-width: 100vw">
          <v-card outlined>
            <div class="d-flex px-2 py-1 align-center">
              <v-icon class="mr-2">mdi-filter-variant</v-icon>
              <v-select
                v-model="projectFilters.type"
                :items="['Corridor', 'Gallerij']"
                dense
                outlined
                hide-details
                placeholder="Type"
                clearable
                class="mr-2"
              />

              <v-select
                v-model="projectFilters.phase"
                :items="distinctTasks"
                dense
                outlined
                hide-details
                placeholder="Phase"
                clearable
                class="mr-2"
              />

              <v-chip
                class="mr-2"
                :color="
                  projectFilters.overdue
                    ? 'var(--v-primary-lighten2)'
                    : 'var(--v-secondary-lighten5)'
                "
                @click="projectFilters.overdue = !projectFilters.overdue"
              >
                Overdue
              </v-chip>

              <v-chip
                class="mr-2"
                :color="
                  projectFilters.wkb
                    ? 'var(--v-primary-lighten2)'
                    : 'var(--v-secondary-lighten5)'
                "
                @click="projectFilters.wkb = !projectFilters.wkb"
              >
                WKB
              </v-chip>

              <v-chip
                class="mr-2"
                :color="
                  projectFilters.redFlags
                    ? 'var(--v-primary-lighten2)'
                    : 'var(--v-secondary-lighten5)'
                "
                @click="projectFilters.redFlags = !projectFilters.redFlags"
              >
                Redflags
              </v-chip>

              <v-chip
                :color="
                  projectFilters.current
                    ? 'var(--v-primary-lighten2)'
                    : 'var(--v-secondary-lighten5)'
                "
                @click="projectFilters.current = !projectFilters.current"
              >
                Current
              </v-chip>
            </div>
          </v-card>
          <v-spacer />
          <v-btn-toggle v-model="ganttScale" mandatory color="primary" rounded>
            <v-btn v-for="option in ganttZoomLevels" :key="option.key">
              {{ option.value }}
            </v-btn>
          </v-btn-toggle>
        </div>
        <v-progress-linear
          color="primary"
          indeterminate
          height="4px"
          :style="{
            opacity: $wait.is('module.project-planning.tasks.get') ? 1 : 0,
          }"
        ></v-progress-linear>
        <dhme-projects-dashboard-gantt-chart
          v-if="!$wait.is('module.project-planning.tasks.get')"
          ref="project-planning-gantt"
          class="flex-1"
          :projects="filteredProjects"
        />
      </div>
      <dhme-projects-dashboard-sidebar
        class="ma-2"
        :style="viewportStyle"
        @update-data="fetchModuleData"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DHME_PROJECTS_DASHBOARD } from '@/modules/modules';
import ViewportStyleMixin from '@/Mixins/ViewportStyleMixin';
import { groupBy } from 'lodash';
import {
  mapProjectToGantt,
  mapTaskToGantt,
} from '@/services/dhtmlx-gantt/chart-helper';
import moment from 'moment/moment';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import DhmeProjectsDashboardSidebar from '@/modules/daiwa-house-modular-europe/ProjectsDashboard/DhmeProjectsDashboardSidebar.vue';
import DhmeProjectsDashboardGanttChart from '@/modules/daiwa-house-modular-europe/ProjectsDashboard/DhmeProjectsDashboardGanttChart.vue';

export default {
  name: 'ProjectsGanttDHTMLX',
  components: {
    DhmeProjectsDashboardGanttChart,
    DhmeProjectsDashboardSidebar,
    ModuleNavigationBar,
  },
  mixins: [ViewportStyleMixin],
  data() {
    return {
      ganttScale: 2,
      ganttZoomLevels: [
        {
          key: 'year',
          value: 'Yearly',
        },
        {
          key: 'quarter',
          value: 'Quarter',
        },
        {
          key: 'month',
          value: 'Monthly',
        },
        {
          key: 'week',
          value: 'Weekly',
        },
        {
          key: 'day',
          value: 'Daily',
        },
      ],
      projectFilters: {
        type: null,
        phase: null,
        overdue: false,
        wkb: false,
        redFlags: false,
        current: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'projects',
      'selectedLicense',
      'authenticatedUser',
    ]),
    ...mapGetters({
      tasks: 'projectsPlanning/licenseTasks',
      data: 'projectsPlanning/data',
    }),
    distinctTasks() {
      return [...new Set(this.tasks.map((p) => p.title))];
    },
    filteredProjects() {
      const currentProjects = this.projects.filter(
        (p) => !p.is_archive && !p.is_master
      );
      const tasksByProjects = groupBy(
        this.tasks,
        (task) => task?.task_project?.id
      );

      return currentProjects
        .filter((p) => {
          const data = this.data.CFFA_DHME_PROJECT_DATA?.records.find(
            (r) => r.project_id === p.id
          );
          let show = true;
          if (this.projectFilters.type) {
            show = data?.type === this.projectFilters.type;
            if (!show) {
              return false;
            }
          }
          if (this.projectFilters.phase) {
            show = tasksByProjects?.[p.id]?.find(
              (t) => t.title === this.projectFilters.phase
            );
            if (!show) {
              return false;
            }
          }
          if (this.projectFilters.overdue) {
            show = tasksByProjects?.[p.id]?.find((t) => {
              const today = moment();
              return moment(t.due).isSameOrBefore(today) && t.status === 'open';
            });
            if (!show) {
              return false;
            }
          }
          if (this.projectFilters.wkb) {
            show = data?.WKB;
            if (!show) {
              return false;
            }
          }
          if (this.projectFilters.redFlags) {
            show = data?.redflags;
            if (!show) {
              return false;
            }
          }
          if (this.projectFilters.current) {
            show = tasksByProjects?.[p.id]?.find((t) => {
              const today = moment();
              if (this.projectFilters.phase) {
                return (
                  t.title === this.projectFilters.phase &&
                  moment(t.planned_start).isSameOrBefore(today) &&
                  moment(t.planned_end).isSameOrAfter(today)
                );
              }
              return (
                moment(t.planned_start).isSameOrBefore(today) &&
                moment(t.planned_end).isSameOrAfter(today)
              );
            });
            if (!show) {
              return false;
            }
          }
          return show;
        })
        .reduce((data, project) => {
          return [
            ...data,
            {
              ...project,
              chartData: {
                ...mapProjectToGantt(project),
                type: 'project',
                render: 'split',
              },
              title: project.name,
            },
            ...(tasksByProjects?.[project.id]?.map((task) => ({
              id: task.id,
              title: task.title,
              chartData: {
                ...mapTaskToGantt(task),
                parent: project.id,
                text: task.title,
              },
            })) || []),
          ];
        }, [])
        .sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
    },
  },
  watch: {
    project: {
      immediate: true,
      handler(value) {
        if (value) {
          this.fetchAllTasks();
          this.fetchModuleData();
        }
      },
    },
    ganttScale(value) {
      this.$refs['project-planning-gantt'].setZoomLevel(
        this.ganttZoomLevels[value].key
      );
    },
  },
  methods: {
    fetchModuleData() {
      if (
        this.project?.modules?.find((m) => m.route === DHME_PROJECTS_DASHBOARD)
      ) {
        this.$store.dispatch('projectsPlanning/fetchModuleData', {
          projectId: this.project?.id,
          moduleId: this.project?.modules?.find(
            (m) => m.route === DHME_PROJECTS_DASHBOARD
          )?.id,
          sessionId: this.$route.params.sessionId ?? null,
        });
      }
    },
    fetchAllTasks() {
      this.$store.dispatch('projectsPlanning/fetchTasks');
    },
  },
};
</script>
<style scoped lang="scss"></style>
